<template>
  <div>
    <v-card tile outlined>
      <v-toolbar color="primary" class="white--text" flat>
        <v-toolbar-title text-center>Reset Password</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-alert type="error" v-if="!!error">{{error}}</v-alert>
        <v-alert type="success" v-if="!!success">{{success}}</v-alert>
        <v-form ref="resetPassword">
          <v-text-field
            type="password"
            label="New Password"
            ref="newPassword"
            v-model="newPassword"
            :rules="passwordRules"
            required
            outlined
            dense
            class="mt-4"
          />

          <v-text-field
            type="password"
            label="Confirm Password"
            ref="confirmPassword"
            v-model="confirmPassword"
            :rules="[passwordConfirmationRule]"
            required
            outlined
            dense
          ></v-text-field>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              large
              block
              :outlined="hover ? false:true"
              :color="hover ? 'primary' : 'primary'"
              class="body"
              tile
              @click="resetPassword"
              :disabled="loading"
            >Reset Password</v-btn>
          </v-hover>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import gateService from '@/services/gate.service';
export default {
  name: "ResetPasswordComponent",
  components: {},
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmPassword || "Password must match";
    },
  },
  data: () => ({
    error: null,
    success: null,
    loading: false,
    valid: true,
    newPassword: null,
    confirmPassword: null,
    passwordRules: [
      v => !!v || "Password is required",
      v => (v && v.length >= 8) || "Password must be at least 8 characters"
    ]
  }),
  methods: {
    async resetPassword() {
      if (this.$refs.resetPassword.validate()) {
        this.loading = true
        await gateService.resetPassword(this.newPassword, this.token)
          .then(
            data => {
              this.success = data.message
              this.$router.push({
                name: "LoginUserPage"
              }).catch(()=>{});
            },
            error => {
              this.error = error.text || 'Oops, that failed. Please try again.'
            }
          )
        this.loading = false
      }
    }
  },
  created() {
    const { token } = this.$route.query
    this.token = token
    this.userId = this.$route.params.userId;
  }
};
</script>
