<template>
  <div>
    <reset-password-component />
  </div>
</template>

<script>
import ResetPasswordComponent from "@/components/gate/ResetPasswordComponent";

export default {
  name: "ResetPasswordPage",
  components: { ResetPasswordComponent },
  computed: {},

  created() {}
};
</script>
